import React from 'react'
import { MDXTag } from '@mdx-js/tag'




export default ({components, ...props}) => <MDXTag name="wrapper"  components={components}><MDXTag name="p" components={components}>{`The Gabii Project aims at representing the kind of decades-long urban excavation that has been rare in the archaeology of Italy, in contrast with projects like those at Corinth, Olympia, Ephesus or Sardis. Strengthened by the collaboration between half a dozen different North American and UK universities, the Gabii Project plans to continue the exploration of a contiguous area in the center of the city for the foreseeable future. In parallel, it intends to continue publishing its results online and in a timely fashion. The attached field school, which has already been attended by several hundred students, will continue to train the next generation of archaeologists and ancient historians.`}</MDXTag></MDXTag>

export const _frontmatter = {"templateKey":"content-pages","title":"Future Plans","backgroundImage":"/assets/20430048_10155580126189025_3626768213911437848_n.jpg","key":"future-plans","parentKey":"the-gabii-project"};

  